import { Observable } from 'rxjs/internal/Observable';
import { PaymentCustomer } from './../../Interfaces/ServiceParameters';
import { Component, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { repeat } from 'rxjs/operators';

import { ApiService } from '../../servicesApi/api/api.service';

import { BarComponent } from "../../pipes/appprogress/progress-bar.component";

/** FIREBASE */
//import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { environment } from '../../../environments/environment'
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import {onBackgroundMessage } from "firebase/messaging/sw";

import * as moment from 'moment-timezone';
import { ServiceParameters } from 'src/app/Interfaces/ServiceParameters';
import { HttpParams } from '@angular/common/http';
import { SharedService } from '../list-vehicle/SharedService/shared.service';
import { StripeService } from 'src/app/services/StripeService/stripe-service.service';
import { clearServiceData, parseDate } from 'src/app/shared/helpers/helpers';
import { interval } from 'rxjs';
import { NavigationService } from 'src/app/services/Navigation/navigation.service';
import { OnExit } from 'src/app/guards/CancelServiceBeforeExit/cancel-service-before-exit.guard';
//import { setInterval } from 'timers';
// Initialize Firebase
//const appFirebase = initializeApp(firebaseConfig);
//const analytics = getAnalytics(appFirebase);
/** ******** */
/** Para el MODAL Permisos */
declare var jQuery:any;
/** ****** */
@Component({
  selector: 'app-drivers-confirmation',
  templateUrl: './drivers-confirmation.component.html',
  styleUrls: ['./drivers-confirmation.component.css'],
  providers: [ApiService],
})
export class DriversConfirmationComponent implements OnInit, OnExit {
  lista = new Array(0);
  data = new Array(0);
  msg: '';
  vehiculos_id: '';
  finished = false; //Finalizo tiempo mostrar mensaje
  //longitud: number = 0;
  flag: boolean = false;  // Bandera Indicador de notificacion

  /** Firebase variables */
  title = 'af-notification';
  message:any = new Array (0);
  /***** */
  service: ServiceParameters;

  public tinit: string;
  private tInterval: any = null;

  public checkoutLink: string = "#";
  public btnConfirmationModal: any = {
    enable: "disabled",
  };
  @ViewChild(BarComponent) child;
  public evtSource: any = null;
  constructor(
    private router: Router, 
    private _api: ApiService,
    private _sh: SharedService,
    private stripeService: StripeService,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private navS: NavigationService
  ) {}

  ngOnInit(): void {
     /** Firebase verifica mensaje */
     //this.activateSSE(localStorage.getItem('id'));
     isSupported().then((sup) => {
      if(!sup){
        //alert("FCM no soportado.\nSe necesita permiso para recibir notificaciones y asi recibir ofertas de los conductores disponibles.");
        window.location.href = "App-prefs://prefs:root=Settings";
        //this.requestPermission();
        this.activateSSE(localStorage.getItem('id'));
      }
      Notification.requestPermission().then(p => {
        //alert(p)
        this.requestPermission();
        this.listen();
      }).catch(e => {
        console.log(JSON.stringify(e));
        //alert(JSON.stringify(e));
      });
     }).catch((sup) => {
      console.log(JSON.stringify(sup));
      //this.tInterval = interval(3000).subscribe((v) => { //Long polling. use to reload driver availables ever 3 seconds
        //this.getPrecioSugerido(localStorage.getItem('userId'));
      //})
     })
     /** ***** */

    

    console.log('iduser ---- ', localStorage.getItem('userId'));
    console.log('idservicio --- ',localStorage.getItem('id'));
    this.service = JSON.parse(localStorage.getItem('service'));
    this.getPrecioSugerido(localStorage.getItem('userId'));
    //this.longitud = this.lista.length;
    this.tinit = localStorage.getItem("created_at_service");
    //console.log("created_at_service: "+localStorage.getItem("created_at_service"));
    /** Desahabilita el botón F5 */
    /*window.addEventListener("keyup", disableF5);
    window.addEventListener("keydown", disableF5);

    function disableF5(e) {
       if ((e.which || e.keyCode) == 116) e.preventDefault();
    };*/
    /** *********** */
    this.router.navigated = false;
    
  }
  onExit() {
    //const exit = confirm('¿Seguro desea salir?');
    //this.cancelService(false);
    //return exit;
    return true;
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.deactivateInterval();
  }
  deactivateInterval(){
    if(this.tInterval){
      this.ngZone.run(() => {
        this.tInterval.unsubscribe();
      });
    }
  }
  /*
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
      event.preventDefault();
      return true;
  }
  */
  @HostListener('window:popstate', ['$event'])
    unloadHandler2(event: PopStateEvent) {
      event.preventDefault();
      console.log(event)
      this.cancelService(false);      
      //alert("Back button Pressed");      
  }
  @HostListener('window:storage', ['$event'])
    onChangeStorage(event: StorageEvent) {
      //event.preventDefault();
    if(localStorage.getItem("thanks_confirmed") === "Y"){
      console.log("Storage changed: ");
      this.router.navigateByUrl('/Thankyou', { skipLocationChange: true }); //Direcciona para Gracias*/
      //clearServiceData();
      localStorage.removeItem("thanks_confirmed")
      // ORIGINAL this.router.navigate(['/Thankyou']); //Direcciona para Gracias
    }

  }
  /** Firebase */
  requestPermission(){
    const messaging = getMessaging();
    getToken(messaging,
     { vapidKey: environment.firebase.vapidKey}).then(
       (currentToken) => {
         if (currentToken) {
           console.log("Hurraaa!!! we got the token.....");
           this._sh.showSuccess("Listo para recibir notificaciones")
           console.log(currentToken);
           this.sendFcmToken(currentToken);
         } else {
          jQuery("#pemisoNotificacion").modal('show'); /** MODAL Permisos Notificación */
          console.log('No registration token available. Request permission to generate one.');

         }
     }).catch((err) => {
        //alert(JSON.stringify(err));
        //this._sh.showError(JSON.stringify(err), "Ocurrió un error al recuperar el token.")
        this._sh.showError("Por favor, refresca la pagina para poder recibir notificaciones del servicio.", "Error")
        console.log('An error occurred while retrieving token. ', err);
        jQuery("#pemisoNotificacion").modal('show');
        
    });
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received --> message --> ', payload);
      //this.message.push(payload);
      //this.flag = true; //LLega notificacion
      console.log('MENSAGE ---> ', payload);
      //this.getPrecioSugerido(this.message.data.driver_id);
      if(payload.data.url == "driver-confirmation"){
        this.getPrecioSugeridoByDriver(localStorage.getItem('userId'), payload.data.driver_id);
      }
    });
    onBackgroundMessage(messaging, (payload) => {
      console.log('Background Message received --> message --> ', payload);
      //this.message.push(payload);
      //this.flag = true; //LLega notificacion
      console.log('MENSAGE ---> ', payload);
      //this.getPrecioSugerido(this.message.data.driver_id);
      if(payload.data.url == "driver-confirmation"){
        this.getPrecioSugeridoByDriver(localStorage.getItem('userId'), payload.data.driver_id);
      }
    });
  }
  /** ***** */
  //Evalua si el tiempo ha finalizado y cancela el servicio
  control($event){
    //alert(this.message.length);
      if($event) {
        if(!this.finished) {
          this.finished = true;
       // this.getPrecioSugerido()
           this.cancelService(false);
        }
      }
  }

  /*control2($event){
    this.finished = true;
    this.lista.length = 0;
  }*/

/*  enviar(vehiculos_id, conductor_id, precio) {
    let body = {
      conductores_id: conductor_id,
      servicios_id: localStorage.getItem('id'),
      vehiculos_id: vehiculos_id,
      precio: precio,
    };

    const dataToSend = new FormData();
    for (var key in body) {
      dataToSend.append(key, body[key]);
    }
    console.log('Vehiculo ID ------', vehiculos_id);

    /*this._api
      .genericPostServi(
        'http://www.api.kamgus.com/usuario-v2/index.php/kamgus/postServConfir',
        //'http://www.api.kamgus.com/v2/general/postServicioAceptado',
        {
          conductores_id: conductor_id,
          servicios_id: localStorage.getItem('id'),
          vehiculos_id: vehiculos_id,
          precio: precio,
        }
      )
      .subscribe(
        (resp: any) => {
          this.msg = resp.text;

          if (!resp.error) {
            this.msg = resp.text;
            console.log(resp.text);
          }
        },
        (err) => {
          // console.log('err');
          console.log(err);
        }
      );

    //localStorage.removeItem('id');
    localStorage.removeItem('userId');
    localStorage.removeItem('service');
    localStorage.removeItem('articles');
    localStorage.removeItem('precio');
    localStorage.removeItem('vehiculos');
    localStorage.removeItem('addDatos');
    localStorage.removeItem('secondTime');
    // ORIGINAL this.router.navigate(['/Thankyou']); //Direcciona para Gracias
    // this.router.navigateByUrl(['/Thankyou'], { skipLocationChange: true }); //Direcciona para Gracias
  }*/

  /*receiveMessage($event) {
    this.finished = $event;
    if (this.finished) console.log('Finished true --- ',this.finished);
    else console.log('Finished false --- ',this.finished);
  }*/

  //Consulta precio sugerido enviado por un conductor especificado .
  async getPrecioSugeridoByDriver(userId: string, driver_id: string = "") {
    if(driver_id.length == 0){
       return;
    }
    let body = {
      usuario_id: userId,
      //usuario_id: localStorage.getItem('userId'),
      //servicio_id: id
      service_id: localStorage.getItem('id'),
      driver_id: driver_id,
    };
    const dataToSend = new FormData();
    for (var key in body) {
      dataToSend.append(key, body[key]);
    }
    await this._api
      .genericGet(
        //'https://www.api.kamgus.com/usuario-v2/index.php/kamgus/suggestedServicePricesInvitado',
        `${environment.endpoints.services.suggested_price}?service_id=${body['service_id']}&driver_id=${body['driver_id']}&usuario_id=${body['usuario_id']}`
      )

      .subscribe(
        (resp: any) => {
          if (!resp.error) {
            console.log("data --- ", resp);
            this.msg = resp.msg;
            this.lista = resp.conductores;
            if (resp.conductores === undefined) {
              return
            };
            if (this.lista.length > 0) {
              this.ngZone.run(()=>{
                this.flag = true;
              })
              this.lista = [...this.lista, (resp.conductores.map((c: any) => {
                c.vehiculos_id = c.plate;
                c.tiempo = c.timeOfArrival;
                return c;
              })[0])];
              this.data = resp.conductores;
              this.message = [...this.message, resp.conductores.map((c: any) =>{
                const time = moment(+c.timeOfArrival).tz("America/New_York").diff(moment().tz("America/Panama"), 'minutes');
                c.nombre = c.name;
                c.nombre_camion = c.vehicle;
                c.placa = c.plate;
                c.precio = c.price;
                c.tiempo = +time < 1 ? "1 min" : time+" mins";
                c.vehiculos_id = c.plate;
                c.conductor_id = c.driver_id;
                c.fotoConductor = c.avatar;
                return c;
              })[0]];
              console.log('lista ---', this.data);

              //this.vehiculos_id = resp.data.vehiculos_id;

              //let prueba = this.child.timerBar();
              //let prueba = this.child.ngOnInit();
              //console.log('prueba ---', prueba);

              //return this.vehiculos_id;
            }
          }
        },
        (err) => {
          // console.log('err');
          console.log(err);
        }
      );
  }
  //Consulta precio sugerido enviado por los conductores .
  async getPrecioSugerido(userId: string) {
    let body = {
      usuario_id: userId,
      //usuario_id: localStorage.getItem('userId'),
      //servicio_id: id
      service_id: localStorage.getItem('id'),
    };
    
    //console.log('body ---- ', body);

    const dataToSend = new FormData();
    for (var key in body) {
      dataToSend.append(key, body[key]);
    }
   // dataToSend.append('usuario_id', localStorage.getItem('userId'));
   // dataToSend.append('servicio_id', localStorage.getItem('id'));

    //console.log('dataToSend---------', dataToSend);
    //console.log("complete ---- ", this.finished);

    await this._api
      .genericGet(
        //'https://www.api.kamgus.com/usuario-v2/index.php/kamgus/suggestedServicePricesInvitado'
        `${environment.endpoints.services.suggested_price}?service_id=${body['service_id']}&usuario_id=${body['usuario_id']}`
      )

      .subscribe(
        (resp: any) => {
          if (resp && !resp.error) {
            console.log("data --- ", resp);
            this.msg = resp.msg;
            this.lista = resp.conductores;
            if (resp.conductores === undefined) {
              return
            };
            if (this.lista.length > 0) {
              this.ngZone.run(()=>{
                this.flag = true;
              })
              this.lista = resp.conductores.map((c: any) => {
                c.vehiculos_id = c.plate;
                c.tiempo = c.timeOfArrival;
                return c;
              });
              this.data = resp.conductores;
              this.message = resp.conductores.map((c: any) =>{
                const time = moment(+c.timeOfArrival).tz("America/New_York").diff(moment().tz("America/Panama"), 'minutes');
                c.nombre = c.name;
                c.nombre_camion = c.vehicle;
                c.placa = c.plate;
                c.precio = c.price;
                c.tiempo = +time < 1 ? "1 min" : time+" mins";
                c.vehiculos_id = c.plate;
                c.conductor_id = c.driver_id;
                c.fotoConductor = c.avatar;
                return c;
              });
              console.log('lista ---', this.data);

              //this.vehiculos_id = resp.data.vehiculos_id;

              //let prueba = this.child.timerBar();
              //let prueba = this.child.ngOnInit();
              //console.log('prueba ---', prueba);

              //return this.vehiculos_id;
            }
          }
        },
        (err) => {
          // console.log('err');
          console.log(err);
        }
      );
}
  sendFcmToken(token: string){
    //const url = "https://www.api.kamgus.com/usuario-v2/index.php/kamgus/uploadTokenFCMInvitado";
    const url = `${environment.endpoints.customer.base}/token`;
    const userId = localStorage.getItem('userId');
    this._api.genericPut(url, {
      token,
      id_usuario: userId,
      platform: 'web',
    }).subscribe(()=>{

    }, (err: any) => {
      console.log("Error guardando fcmToken: ", err);
    })
  }
  //Inicia proceso de pago con Stripe del servicio
  sendPayment(driver: any){
    /*
    fetch( environment.END_STRIPE + "paymentIntentInvitado" , {
			method: 'PUT',
			body: JSON.stringify({
			  user_id: localStorage.getItem('userId'),
			  service_id: localStorage.getItem('id'),
			  payment_method: (this.service.options.paymentData as PaymentCustomer).payment_method,
			  value: driver.precio, //Este campo se dejara de usar en version posterior de la api
        driver_id: driver.conductor_id
			}),
			headers:{
			  'Content-Type': 'application/json'
			}
		  }).then((res:any) => res.json())
		  .catch((error:any) => {
  			console.error('Error:', error)
  			alert("Error finalizando pago: "+ JSON.stringify(error));
        //debugger;
		  })
		  .then((response:any) => {
        if(response.error){
          alert(response.code+": "+ response.msg);
          this.cancelService();
          return;
        }
  			alert("The service is satisfactorily paid");
        
        this.enviar(driver); //Envia aceptacion del precio al conductor
    		
  
		  });
      */
      jQuery("#confirmation_modal").modal('show');
     this.stripeService.initializeCheckoutSession({
      idservicio: localStorage.getItem('id'),
      driver_id: driver.conductor_id,
    }).subscribe((resp: any) => {
      if(resp.data && resp.data.status == "complete" && !resp.data.url){
        this.enviar(driver)
        return;
      }
      if(resp.data && resp.data.url){
        //let nwindow = window.open(resp.data.url, "_blank", "popup=1");
        //if(nwindow){
        //  nwindow.focus();
        //}
        this.checkoutLink = resp.data.url;
        this.btnConfirmationModal.enable = "";
        /*
        nwindow.addEventListener("focus", (e)  => {
          console.log("Focus event 1")
        })
        nwindow.addEventListener("blur", (e)  => {
          console.log("Focus event 2")
        })
        nwindow.addEventListener("pagehide", (e)  => {
          console.log("Focus event 3")
        })
        */
        const doSomething = (e: any)  => {
          console.log("Focus event 4");
          if(this.service.options.typePay == "credito"){
            let params = {
              idservicio: localStorage.getItem('id'),
            };
            this.stripeService.refreshCheckoutSessionStatus(params).subscribe((resp: any) => {
              console.log(resp)
              if(resp.data && (resp.data.status == "complete" || resp.data.status == "succeeded")){
                this.enviar(driver);
                document.removeEventListener("visibilitychange", doSomething);
              }
            });
          }
        };
        document.addEventListener("visibilitychange", doSomething);
      }
     });
  }
  cancelService(navigate: Boolean = true) {
    let params = new HttpParams().set("servicio_id", localStorage.getItem('id'));
    
    this._api.genericPut(`${environment.endpoints.services.cancel}`, params).subscribe(
      (ok) => {
        jQuery("#pemisoNotificacion").modal('hide');
        this._sh.showSuccess(
          "Se ha cancelado el servicio",
          "Exitosamente.."
        );
        
        //this._emmiter.reloadComponent();
        if(navigate) {
          //this.router.navigate(["/"]);
          this.navS.back();
        }
      },
      
      (err) => {
        this._sh.showError(
          "Oooppp hubo un error",
          "Intentalo nuevmante..." + err.msg
        );
        
      }
    );
  }
  onSelectDriver(driver: any){
    //inicia proceso de pago del servicio y activa el servicio
    localStorage.setItem("driver", JSON.stringify(driver));
    localStorage.removeItem("thanks_confirmed");
    if(this.service.options.typePay == "credito"){
      this.sendPayment(driver); //Inicia el proceso de pago con tarjeta
      return;
    }else
    if(this.service.options.typePay == "yappy"){
      this.makePaymentByYappy(driver, localStorage.getItem('id')); //Inicia el proceso de pago con yappy
      return;
    }
    this.enviar(driver); //Envia aceptacion del precio al conductor

  }
  driverTimeOut(driver: any){
    this.message = this.message.filter(d => d.driver_id != driver.driver_id);
    if(this.flag && this.message.length == 0){ //Si no hay mas conductores disponibles y el servicio se cancela pasado el tiempo
      this._sh.showError("Ninguna oferta fue aceptada");
      this.cancelService(false);
    }
  }
  //envia solicitud para activar el servicio
  enviar(driver: any) {
    console.log(driver);
    const {vehiculos_id, conductor_id, precio, registro} = driver;
      let body: any = {
        conductores_id: conductor_id,
        servicios_id: localStorage.getItem('id'),
        vehiculos_id: vehiculos_id,
        precio: precio,
        role: registro,
      };
      /*
      if(this.service.options.typePay == "credito"){
        body.customer_id = (this.service.options.paymentData as PaymentCustomer).customer_id;
        body.payment_method = (this.service.options.paymentData as PaymentCustomer).payment_method;
      }*/
      const dataToSend = new FormData();
      for (var key in body) {
        dataToSend.append(key, body[key]);
      }
      console.log('Vehiculo ID ------', vehiculos_id);

      this._api
        .genericPut(
          `${environment.endpoints.services.confirm_driver}`,
          //'http://www.api.kamgus.com/usuario-v2/index.php/kamgus/postServConfir',
          //'http://www.api.kamgus.com/v2/general/postServicioAceptado',
          {
            driver_id: conductor_id,
            servicio_id: localStorage.getItem('id'),
            vehiculos_id: vehiculos_id,
            precio: precio,
            role: registro,

          }
        )
        .subscribe(
          (resp: any) => {
            this.msg = resp.text;

            if (!resp.error) {
              this.msg = resp.text;
              console.log(resp.text);
              if(resp.data && resp.data.url){
                
              }
              this.deactivateInterval();
              clearServiceData();
            }
          },
          (err) => {
            // console.log('err');
            console.log(err);
          }
        );

      //localStorage.removeItem('id');
      
      // ORIGINAL this.router.navigate(['/Thankyou']); //Direcciona para Gracias
       this.router.navigateByUrl('/Thankyou', { skipLocationChange: true }); //Direcciona para Gracias*/
  }
  //Inicia proceso de pago con Yappy del servicio
  makePaymentByYappy(driverId: any, serviceId: string){
    this._api
        .genericPostServi(
          `${environment.endpoints.payment}?type=2`,
          {
            driver_id: driverId.conductor_id,
            id_servicio: serviceId,
          })
          .subscribe(
            (resp: any) => {
              this.msg = resp.text;
  
              if (resp && !resp.error) {
                this.msg = resp.text;
                console.log(resp.text);
                if(resp.data && resp.data.url){
                  //var nWindow = window.open(resp.data.url, "_blank");
                  //nWindow.focus();
                  jQuery("#confirmation_modal").modal('show');
                  this.checkoutLink = resp.data.url;
                  this.btnConfirmationModal.enable = "";
                }
              }
            },
            (err) => {
              // console.log('err');
              if(err.status == 502){
                alert(err.error.msg);
              }
              console.log(err);
            }
          );
  }
  getserviceStatus(serviceId: string): Observable<any>{
    return this._api.genericGet("https://www.api.kamgus.com/usuario-v2/index.php/kamgus/serviceStatusInvitado/"+serviceId);
  }
  openPopUp(e: Event){    
    e.preventDefault();
    console.log(this.checkoutLink)
    window.open(this.checkoutLink, "MsgWindow", "top=500,left=500,width=500,height=800");
    jQuery("#confirmation_modal").modal('hide');
    this.deactivateInterval();
    if(this.service.options.typePay == "yappy"){
      this.activeYappyLongPoilling(localStorage.getItem("id"), JSON.parse(localStorage.getItem("driver")));
    }
    
  }
  activeYappyLongPoilling(serviceId: string, driverId: string){
    let count = 0;
    const tOut = setInterval(() => {
      if(count > 120){
        //this.enviar(driverId);
        clearInterval(tOut);
        return;
      }
      this.getserviceStatus(serviceId).subscribe((data: any) => {
        console.log(data, data.data.Response, data.data);
        if(data && (data.data.Response == 'success' || data.data.Response == 'complete')){
          this.enviar(driverId);
          this.deactivateInterval();
          clearInterval(tOut);
          return;
        } else
        if(data && data.data.Response == 'fail'){
          console.log("Transacción fallida");
          clearInterval(tOut);
          this.cancelService(false);
          return;
        }
      });
      count++;
    }, 2500);
  }
  activateSSE(serviceId: string){
    this.evtSource = new EventSource(environment.endpoints.services.sse.suggested_price+"?sid="+serviceId);
    this.evtSource.onopen = function() {
      console.log("Connection to server opened.");
    };
  
    this.evtSource.onmessage = function(e) {
      
    };
  
    this.evtSource.onerror = function() {
      console.log("EventSource failed.");
    };
    this.evtSource.addEventListener("SSE_SUGGESTED_PRICE", (eve: any) => {//Evento usado para obtener cada mensaje enviado por el servidor
      const data = JSON.parse(eve.data);
      if(data.hasMore){//si hay mas ofertas de conductores; refresca la lista de conductores con precio sugerido
        this.getPrecioSugerido(localStorage.getItem('userId'));
      }
    });
  }
}
