import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Route } from '@angular/compiler/src/core';
//Maps
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import Swal from 'sweetalert2'
//api
import { ApiService } from '../../servicesApi/api/api.service';
import { ServiceParameters } from 'src/app/Interfaces/ServiceParameters';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-point-to-point',
  templateUrl: './point-to-point.component.html',
  styleUrls: ['./point-to-point.component.css'],
  providers: [ ApiService]
})
export class PointToPointComponent implements OnInit {

  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  lat: any = 9.0855953;
  lng: any = -79.3865497;
  origin: any = null;
  destination: any = null;
  zoom = 15;
  options = {
    componentRestrictions: { country: 'PA' },
  };
  inicio = '';
  fin = '';
  //tipoVehiculo = 'articulo';
  tipoVehiculo = 'vehiculo';
  tipoPago = 'efectivo';
  tipoProgramacion = 'ACTIVO';
  //tipoProgramacion = 'ahora';
  locationMap = true;
  tiempo: any = null;
  now;
  minDate;
  verificar:boolean = true;
  public version: string = environment.version;
  public actived: boolean = true;
  constructor( private _api: ApiService, private route: Router, private datePipe: DatePipe ) { }

  ngOnInit() {
    this.verificarEnpoint();
    this.getLocation();
    //this.detectMovile();
    //this.detectIos();
    localStorage.removeItem('service');
    localStorage.removeItem('id');
    //localStorage.removeItem('articles');
    this.now = new Date();
    this.minDate = new Date();
  }

  verificarEnpoint(){
    //this._api.genericGet( `https://apiv2.kamgus.com/api/v2/articles` ).subscribe((data:any) => {
    this._api.genericGet( environment.endpoints.articles.base ).subscribe((data:any) => {      
      this.verificar = true;
      
    },err => {
      console.log( err );
      alert('Debido a Problemas con la Conexion no se puede continuar con el servicio')
      this.verificar = false;
    });   
    
  }

  
   detectMovile() {
    if( navigator.userAgent.match(/Android/i))
    { 
      Swal.fire({
        title: 'Bienvenido',
        html:
          '<p style="font-size:14px">Si quiere disfrutar de una mejor experiencia, descarga nuestra aplicacion movil en</p> ' +
          '<a target="blank" href="https://play.google.com/store/apps/details?id=com.kamgusu.pa"><img src="../../../assets/images/google.png"style="height: 40px; width:90%; margin-top:15px"/></a>',
          
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
        toast:true,
        position:'bottom',
        

        
      })
    }
  }

  detectIos() {
    if( 
       navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    )
    { 
      Swal.fire({
        title: 'Bienvenido',
        html:
          '<p style="font-size:14px">Si quiere disfrutar de una mejor experiencia, descarga nuestra aplicacion movil en: </p> ' +
          '<a  target="blank" href="https://apps.apple.com/us/app/id1495896386">  <img src="../../../assets/images/ios.png" style="height: 40px ; width:90%; margin-top:15px"  /> </a>' , 
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
        toast:true,
        position:'bottom',
        
      })
    }
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          /*console.log('Latitude: ' + position.coords.latitude +
            'Longitude: ' + position.coords.longitude);*/
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          /*console.log(this.lat);
          console.log(this.lat);*/
        }
      },
        (error) => console.log(error));
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

  handleAddressChange(location: any) {
    //console.log('handeladresschange', location.geometry.location.lng());
    this.inicio = location.formatted_address;
    this.origin = {
      lat: location.geometry.location.lat(),
      lng: location.geometry.location.lng(),
    };

    this.lat = this.origin.lat;
    this.lng = this.origin.lng;

    if (this.origin != null && this.destination != null) {
      this.locationMap = false;
      this.zoom = 10;
      const directionsService = new google.maps.DirectionsService();
      const directionsDisplay = new google.maps.DirectionsRenderer();
      this.calculateAndDisplayRoute(directionsService, directionsDisplay);
    }
  }

  public handleAddressChange2(location2: any) {
    this.fin = location2.formatted_address;
    this.destination = {
      lat: location2.geometry.location.lat(),
      lng: location2.geometry.location.lng(),
    };

    if (this.origin != null && this.destination != null) {
      this.locationMap = false;
      this.zoom = 10;
      const directionsService = new google.maps.DirectionsService();
      const directionsDisplay = new google.maps.DirectionsRenderer();
      this.calculateAndDisplayRoute(directionsService, directionsDisplay);
    }
  }

  calculateAndDisplayRoute(directionsService, directionsDisplay) {
    /*console.log('origen', this.origin);
    console.log('destination', this.destination);*/
    directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: this.origin,
        destination: this.destination,
        waypoints: [],
        optimizeWaypoints: true,
        travelMode: 'DRIVING',
      },
      (response, status) => {
        if (status === 'OK') {
          this.tiempo = response.routes[0].legs[0];
          console.log('tiempo', response.routes[0].legs[0]);
        }
      }
    );
  }
  next() {
    //return;
    const params: ServiceParameters = {
      time: this.tiempo,
      location: {
        origin: this.origin,
        destination: this.destination
      },
      coords: {
        lat: this.lat,
        lng: this.lng
      },
      options: {
        start: this.inicio,
        end: this.fin,
        typeVehicle: this.tipoVehiculo,
        typePay: this.tipoPago,
        typeProgram: this.tipoProgramacion,
        date: (new Date(this.now)).toISOString(),


      }
    };
      
     /* localStorage.setItem('service', JSON.stringify(params));
    if (this.tipoVehiculo === 'articulo') {
      this.route.navigate(['/list-vehicle']);
    } else {
      this.route.navigate(['/list-vehicle']);
    }  */
 
    localStorage.setItem('service', JSON.stringify(params));
    if (this.tipoVehiculo === 'articulo') {
      this.route.navigate(['/list-article']);
    } else {
      localStorage.removeItem('articles');
      this.route.navigate(['/list-vehicle']);
    }
 
    
  }
  clearStart(){
    this.inicio = '';
  }
  clearEnd(){
    this.fin = '';
  }
  validateDate(e: any){
    if((new Date(this.now)).getTime() < this.minDate.getTime()){
      this.now = this.datePipe.transform(new Date(new Date().getTime() + 60000), "yyyy-MM-ddThh:mm");
    }
    
  }
}
