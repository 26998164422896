//--------------------------	Crhistian L 	---------------------
//Limpia los datos del servicio
export const clearServiceData = () =>{
    localStorage.removeItem('userId');
    localStorage.removeItem('service');
    localStorage.removeItem('articles');
    localStorage.removeItem('precio');
    localStorage.removeItem('vehiculos');
    localStorage.removeItem('addDatos');
    localStorage.removeItem('secondTime');
};

//convierte un text de fecha a timestamp
export const parseDate = (strDate: string, timezone: string) => {
    
    let result = Date.parse(`${strDate} ${timezone}`);
    if(isNaN(result)){
        strDate = strDate.replace("-", "/");
        strDate = strDate.replace("-", "/");
        result = Date.parse(`${strDate} ${timezone}`);
        //debugger;

    }
    //console.log("Date parse: ", result);
    return result;
}
function notifyMe() {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      const notification = new Notification("Hi there!");
      // …
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          const notification = new Notification("Hi there!");
          // …
        }
      });
    }
  
    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them anymore.
}