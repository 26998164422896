import { ServiceParameters, PaymentCustomer } from './../../../Interfaces/ServiceParameters';
import { Route } from '@angular/compiler/src/core';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { repeat } from 'rxjs/operators';

import { ApiService } from '../../../servicesApi/api/api.service';

import { BarComponent } from "../../../pipes/appprogress/progress-bar.component";
import { environment } from 'src/environments/environment';


@Component({
  selector: 'driver-servicio',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.css'],
  providers: [ApiService],
})

export class DriversComponent implements OnInit {
  lista = new Array(0);
  data = new Array(0);
  msg: '';
  info: any;
  //vehiculos_id: '';
  finished: boolean = false;
  flag: boolean = false;
  service: ServiceParameters;
  @Input() message: any;
  @Output() onDriverSelected = new EventEmitter<any>(); //Evento usado para emitir que ha sido seleccionado
  @Output() onTimeOut = new EventEmitter<any>(); //Evento usado para emitir tiempo finalizado.
   constructor(private _api: ApiService, private router: Router) {}

   ngOnInit() {
     console.log('MENSAJE EN COMPONENTE _-_> ', this.message);
     this.service = JSON.parse(localStorage.getItem('service'));
    /*
    this.message.forEach((id: any) => {
      this.info = id.data;
      console.log('INFOR ----> ',this.info);

    });
    */
   this.data[0] = this.message;
   this.info = this.message;
   console.log(this.message);
   this.getPrecioSugerido(this.message.driver_id);
    //this.getPrecioSugerido(this.message.data.userId);


  }


  control($event){
    if($event) {
      if(!this.finished) {
        this.finished = true;
        this.onTimeOut.emit(this.message);
     // this.getPrecioSugerido()
      }
    }
  }

  async getPrecioSugerido(userId) {
      let body = {
        usuario_id: userId,
        //usuario_id: localStorage.getItem('userId'),
        //servicio_id: id
        service_id: localStorage.getItem('id'),
      };

      //console.log('body ---- ', body);

      const dataToSend = new FormData();
      for (var key in body) {
        dataToSend.append(key, body[key]);
      }
     // dataToSend.append('usuario_id', localStorage.getItem('userId'));
     // dataToSend.append('servicio_id', localStorage.getItem('id'));

      //console.log('dataToSend---------', dataToSend);
      //console.log("complete ---- ", this.finished);

      await this._api
        .genericGet(
          `${environment.endpoints.services.suggested_price}?service_id=${body['service_id']}&usuario_id=${body['usuario_id']}`
          
        )

        .subscribe(
          (resp: any) => {
            if (!resp.error) {
              console.log("data --- ", resp);
              this.msg = resp.msg;
              this.lista = resp.data;
              if (resp.data === undefined) {
                return
              };
              if (this.lista.length > 0) {
                this.lista = resp.data;
                resp.data.vehiculos_id = resp.data.plate;
                resp.data.tiempo = resp.data.timeOfArrival;
                this.data = [resp.data]
                console.log('lista ---', this.data);

                //this.vehiculos_id = resp.data.vehiculos_id;

                //let prueba = this.child.timerBar();
                //let prueba = this.child.ngOnInit();
                //console.log('prueba ---', prueba);

                //return this.vehiculos_id;
              }
            }
          },
          (err) => {
            // console.log('err');
            console.log(err);
          }
        );
  }

  enviar(vehiculos_id: any, conductor_id: any, precio: any) {
    this.onDriverSelected.emit({vehiculos_id, conductor_id, precio});
  }
  
}

