<div class="modal" id="iconoRojo" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header headerIcono">
        <h4 class="modal-title">Importante</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modalIcono">
        <div class="col-md-12 mt-1 mb-3 texIconoModal">
          <p class="text-center">
            <span class="texto-totalPrice"
              >Precio Promedio {{ value }} USD</span
            >
          </p>
          <span>
            El precio sugerido esta muy por debajo del promedio. Puede Solicitar
            un Kamgus sin Embargo es muy posible no tener respuesta de ningun
            conductor</span
          >
        </div>

        <button
          type="button"
          class="btn btnmodalIcono"
          data-dismiss="modal"
          aria-label="Close"
        >
          Continuar
        </button>
      </div>
    </div>
  </div>
</div>
