<app-header></app-header>
<div class="ContenedorDriversConfirmation">
    <div class="row mtop-negative">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-8 titulocontenedor">
                    <h5 class="titulo"><span>CONFIRMACIÓN DE CONDUCTORES</span></h5>
                    <p>
                        <span>Esperando confirmación de precio por nuestros socios
                            conductores</span>
                    </p>
                </div>

                <div class="col-md-4 contenedorbtnAtras">
                    <button type="button" class="btn waves-effect waves-light btn-rounded btn-small btnAtras" (click)="cancelService(true)"
                        >
                        <p><span>ATRAS</span></p>
                    </button>
                </div>
            </div>
            <div class="separador">

            </div>

        </div>
    </div>
    <!-- Imagen cargando tiempo-->
    <!--<div class="row"  *ngIf="!finished && !flag; else MensajePrecio">-->
    <div class="row" *ngIf="!finished && !flag">
        <div class="col-md-12">
            <!--<app-progress-bar (complete)="finished = true" [lista]="longitud"></app-progress-bar>-->
            <!--<app-progress-bar (complete)="receiveMessage($event)" [lista]="longitud"></app-progress-bar>-->
            <app-progress-bar 
                *ngIf="!finished && !flag" 
                (complete)="control($event)"
                [tinit]="tinit"
                [tTimeZone]="'UTC'"
                [flag]="flag"
                timer="true">
            </app-progress-bar>
            
            <div class="loader" *ngIf="!finished && !flag">

            </div>
        </div>

    </div>
    <!-- Modal Precio Sugerido -->
    <div class="modal pemisoNotificacion" id="pemisoNotificacion" tabindex="-1">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Advertencia</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>Esta WEB requiere de Permisos para recibir notificaciones, por favor acepte los mismos.</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal Continuar checkout -->
    <div class="modal confirmation_modal" id="confirmation_modal" tabindex="-1">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Muy bien</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    Iniciando pago
                </div>
                <div class="modal-footer">
                    <a href="#" [class]="'btn btn-primary '+btnConfirmationModal.enable" (click)="openPopUp($event)" >Abrir</a>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  </div>
            </div>
        </div>
    </div>
    <!-- Mensaje de no encontrar respuesta-->
    <!--<ng-template #MensajePrecio>-->
    <div class="row" *ngIf="finished">
        <div class="col-md-12">
            <div class="mensaje">
                <div class="titulocontenedorMensaje">
                    <h5 class="titulo"><span>NINGÚN CONDUCTOR ACEPTO SU TARIFA VUELVA A INTENTAR</span></h5>
                    <p style="text-align: center;"><span>Si no consigue conductor escribanos al whatsapp</span></p>
                    <button type="button" class="btn waves-effect waves-light btn-rounded btn-small btnWhatsapp">
                        
                        <a href="https://wa.me/message/DWODI7S35GIVL1" *ngIf="false">
                            <p><span>CHAT POR WHATSAPP</span></p>
                        </a>
                        <a href="https://wa.me/+5073970770">
                            <p><span>CHAT POR WHATSAPP</span></p>
                        </a>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!--</ng-template>-->
    <!-- Hasta Aquí mensaje de no encontrar respuesta-->
    <!-- Mensaje de no aceptar precios de conductores-->
    <ng-template #MensajeNoPrecio>
        <div class="row">
            <div class="col-md-12">
                <div class="mensaje">
                    <div class="titulocontenedorMensaje">
                        <h5 class="titulo"><span>NO ACEPTO NINGUNA TARIFA DE LOS CONDUCTORES</span></h5>
                        <p style="text-align: center;"><span>Por favor, escribanos al whatsapp</span></p>
                        <button type="button" class="btn waves-effect waves-light btn-rounded btn-small btnWhatsapp">
                            <a href="https://wa.me/message/DWODI7S35GIVL1">
                                <p><span>CHAT POR WHATSAPP</span></p>
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <!-- Hasta Aquí mensaje de no aceptar precio de conductores-->

    <!--Fila 2-->
    <br />
    <!-- Lista conductores que han enviado su precio ofrecido para el servicio -->
    <div class="row">
        <div *ngFor="let item of message; let i = index" class="col-md-4">
            <driver-servicio *ngIf="message.length > 0  && !finished;" [message]="item" (onDriverSelected)="onSelectDriver($event)" (onTimeOut)="driverTimeOut($event)"></driver-servicio>
        </div>
    </div>




</div>
