import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../servicesApi/api/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirm-articles',
  templateUrl: './confirm-articles.component.html',
  styleUrls: ['./confirm-articles.component.css'],
  providers: [ ApiService ]
})
export class ConfirmArticlesComponent implements OnInit {



  articles = [];
  subCategories = [];
  subCategorias = [];
  articlesSelected = [];
  customArticles = [];
  loader:boolean= true;

  constructor(private _api: ApiService,  private router:Router) { }

  ngOnInit() {
    this.loadSubCategories();
  }
  loadArticlesLocal() {
    if ( localStorage.getItem('articles') !== null ) {
      this.articlesSelected = JSON.parse(  localStorage.getItem('articles') );
      // console.log('articlesSelected', this.articlesSelected);
      this.loadCustom();
      this.mutableArticles( this.articlesSelected );
    }
  }
  loadCustom() {
    this.customArticles = this.articlesSelected.filter( item => item.id_articulo_acarreo_2 === 'custom' );
  }
  loadSubCategories() {
    // this._api.genericGet('https://apiv2.kamgus.com/api/v2/subcategories').subscribe( (resp:any) =>{

    this._api.genericGet(`${environment.endpoints.articles.base}/all`).subscribe( (resp:any) =>{
    this.subCategories = resp.articulos;
    if(!this.subCategories){
      console.log('')
    }else{
      this.loader=false;
    }
    // console.log(this.subCategories);
    //console.log(this.articles)
    this.loadArticlesLocal();
    //this.loadsubCategorias();
    });

  }

  mutableArticles( objects: any ) {
    const values = {};
    const articles = objects;
    // console.log(objects)
    // tslint:disable-next-line: no-shadowed-variable
    articles.map( ( articles: object, index ) => {
      const key: string = articles['id_articulo_acarreo_2'];
      //sub_category_id; id_articulo_acarreo_2
      values[ key ] = values[ key ] ||  { category: key, items: [] };
      const { items } = values[ key ];
      items.push( { ...articles, index } );
    });
    this.articles = Object.values( values );
    this.mappingNameCategory();
    // console.log(this.articles)
  }
  mappingNameCategory() {
    this.articles.forEach(element => {
      if ( element['category'] === 'custom' ) {
        element.nombre = 'Personalizado';
        element.imagen = 'https://app.kamgus.com/assets/images/ICONOS-15.png';
        // element.img = '';
      }
      this.subCategories.forEach(sub => {
      //  console.log(sub);
        if ( element['category'] === sub['id'] ) {
          element.nombre = sub.nombre;
          element.imagen = sub.imagen;
          // element.img = sub.img;

        }
      });

    });
  }

  addQuatity( index ) {
    //console.log( index );
    this.articlesSelected.forEach( element => {
      if ( element.id === index.id ) {
        element.cantidad =  element.cantidad + 1;
      }
    });
    localStorage.setItem('articles', JSON.stringify( this.articlesSelected ) );
    this.loadArticlesLocal();
  }
  removeQuatity( index ) {
    this.articlesSelected.forEach( element => {
      if ( element.id === index.id ) {
        if ( element.cantidad > 1) {
          element.cantidad =  element.cantidad - 1;
        }
      }
    });
    localStorage.setItem('articles', JSON.stringify( this.articlesSelected ) );
    this.loadArticlesLocal();
  }
  delete( article ) {
    const tmp = this.articlesSelected.filter( item => item.id !== article['id']);
    localStorage.setItem('articles', JSON.stringify( tmp ) );
    this.loadArticlesLocal();
  }


}
