import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges, Renderer2, ViewChild, ChangeDetectorRef } from '@angular/core';
import { timer } from 'rxjs';
import { parseDate } from 'src/app/shared/helpers/helpers';
//import { setTimeout } from 'timers';


@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})

export class BarComponent implements OnInit {
   progress: number = 0;
   //resultado: any;
   color: string = 'red';
   timeOutIDs:number[] = [];
   public timeOut: string = "15:00";
    
   @Input() tinit: any;
   @Input() tTimeZone: any = "EDT";
   @Input() flag: boolean;
   @Input() timer: boolean = false;
   //@Input() color: string;
   @Output() complete = new EventEmitter();
   //@Output() finished: boolean;
   private t = 900000; //Tiempo de duracion de la barra definido en segundos.
   //private final: Date = new Date(new Date().getTime() + this.t);
   private final: Date;
   private finalTOut: any; 
   constructor(private cd: ChangeDetectorRef) { }

   ngOnInit() {
    console.log("Tiempo Inicial: ", this.tinit);
    this.final = new Date(new Date().getTime() + (this.t));
    if(this.tinit ){
      this.final = new Date(parseDate(this.tinit, this.tTimeZone) + (this.t));
      this.progress = Math.round(((new Date().getTime() - parseDate(this.tinit, this.tTimeZone)) * 100.0) / this.t);
      //console.log(parseDate(this.tinit, this.tTimeZone), new Date().getTime(), this.final.getTime());
      //console.log("Progress: ",this.progress);
    }
    
    //console.log("Serv time: "+"\n"+this.final+"\n"+this.tinit+"\n"+new Date()+"\n"+new Date(new Date().getTime() + (this.t))+"\n"+this.tinit+"\n"+new Date(parseDate(this.tinit, tTimeZone) + (this.t)));
    this.timeOutIDs.forEach(id => clearTimeout(id));
    
    this.timerBar(); //Inicia el desplazamiento de la barra de progreso
    console.log("Time: ",this.tinit);

    /**
     * Inicia la cuenta regresiva
     */
    let timeDifference = this.final.getTime() - (new Date()).getTime();
    this.finalTOut = setInterval(() => {
      timeDifference = this.final.getTime() - (new Date()).getTime();
      //console.log("time Diff: ", timeDifference);
      const current = new Date(timeDifference);
      //en caso de que la diferencia de tiempo entre el tiempo final y el actual sea negativa, muestre 0:00
      if((timeDifference) < 0){
        this.timeOut = "0:00";
        console.log(this.timeOut, timeDifference)
        return;
      }
      this.timeOut = ""+current.getMinutes()+":"+(current.getSeconds() < 10 ? "0"+current.getSeconds() : current.getSeconds());
      //console.log(this.timeOut, timeDifference)
    }, 1000);
    if((timeDifference) < 0){ // Comprueba si el el tiempo de expiracion ya se ha cumplido.
      this.progress = 99;
      setTimeout(() => {
        this.doFinish();        
      }, 100);
    }
    this.cd.detectChanges();
    document.addEventListener("visibilitychange", () => {
      if(this.tinit ){
        this.progress = Math.round(((new Date().getTime() - parseDate(this.tinit, this.tTimeZone)) * 100.0) / this.t);
      }
    })
  }


   private timerBar = function()  {
      /* if(this.finished === true){
        this.progress = 100;
        return this.progress;
      }*/

      
      if (++this.progress > 99 || this.flag != false) {
        this.doFinish();
      }

      this.timeOutIDs.push(
        setTimeout(()=>{
          console.log("waited for: " + this.progress + " seconds");
          this.timerBar();
        }, this.t / 100.0)
      );
   }

  ngOnDestroy() {
    this.timeOutIDs.forEach(id => clearTimeout(id));
    clearTimeout(this.finalTOut);
  }
  doFinish(){
    this.progress = 100;
    //this.flag = false;
    this.timeOutIDs.forEach(id => clearTimeout(id)); //Detiene la barra de progreso
    clearTimeout(this.finalTOut); //Detiene la cuenta regresiva
    return this.complete.emit(true); //Emite el evento complete
  }
}
