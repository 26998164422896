import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modales-iconos3',
  templateUrl: './modales-iconos3.component.html',
  styleUrls: ['./modales-iconos3.component.css']
})
export class ModalesIconos3Component implements OnInit {
  @Input() value: string = "";
  constructor() { }

  ngOnInit(): void {
  }

}
