import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modales-iconos',
  templateUrl: './modales-iconos.component.html',
  styleUrls: ['./modales-iconos.component.css']
})
export class ModalesIconosComponent implements OnInit {
  @Input() value: string = "";
  constructor() { }

  ngOnInit(): void {
  }

}
