<div class="footerRow">
    <div class="col-md-5 text">
        <p class="texto m-0" style="
          font-size: 16px;
          text-align: left;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.6);
        ">
            © {{currentYear}} Kamgus Corp. Desarrollado por  
            <a target="blank" href="https://www.appsolutionss.com/">APPSolucionss</a>
            <span>
              versión: 
                <span style="
                    font-size: 16px;
                    text-align: left;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.6);
                  ">
                  {{version}}
                </span>
            </span>
        </p>
    
  </div>
  <div class="col-md-4"></div>
  <div class="col-md-3 tarjetas">
    <div class="col-md-3 responsive"></div>
    <img
      class="iconostarjetas"
      style="width: 19vw; text-align: right"
      src="../../../assets/images/payments.png"
    />
    <!--<img
      class="iconostarjetas"
      style="width: 4vw; text-align: right"
      src="../../../assets/images/logoYappy.jpg"
    />-->
  </div>
</div>

<!--

          <img
          style="width: 5vw; "
          src="../../../assets/images/visa.png"
        />
        <img
          style="width: 5vw; "
          src="../../../assets/images/master.jpg"
        />

            <img
              style="width: 4vw; "
              src="../../../assets/images/logoYappy.jpg"
            />

-->
