import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//api
import { ApiService } from '../../servicesApi/api/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AbsoluteSourceSpan } from '@angular/compiler';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-article',
  templateUrl: './list-article.component.html',
  styleUrls: ['./list-article.component.css'],
  providers: [ApiService],
})
export class ListArticleComponent implements OnInit {
  quantityCart = 0;
  categories: any[] = [];
  subCategories: any[] = [];
  articles: any[] = [];
  articlesSelected = [];
  SelectArticulos: any[] = [];
  name;
  articulos = [];
  loader: boolean = true;
  formData: FormGroup;
  tmpCat = '';
  customArticles = [];
  idCategoria;
  //Buscador
  articulos2 = [];
  idSubcategoria = [];
  subCategoriesSearch = [];
  subCategoriesSearch2 = [];
  //Scroll Infinito
  termino: string = '';
  allpost;
  notEmptyPost = true;
  notscrolly = true;
  service: any;

  miFormulario: FormGroup = this.formBuilder.group({
    data: ['', Validators.required],
  });

  constructor(
    private _api: ApiService,
    private formBuilder: FormBuilder,
    private route: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.getCategories();
    this.loadArticlesLocal();
    this.loadForm();
    this.selectArticles();
    this.selectArticles2();
    //Scroll Infinito
  }
  /*Formulario Select */

  guardar(article: string) {
    var articulos = this.articles.find((item) => item.id == article);
    if(!articulos){
      console.error("Articulo no encontrado");
      return;
    }
    articulos['cantidad'] = 1;
    this.articlesSelected.push(articulos);
    localStorage.setItem('articles', JSON.stringify(this.articlesSelected));
    this.loadArticlesLocal();

    /* console.log(this.articlesSelected)
      console.log(article)  */
  }

  /*2a*/
  coutCart() {
    this.quantityCart = 0;
    this.articlesSelected.forEach((element) => {
      this.quantityCart += element.cantidad;
    });
  }
  /*2*/
  loadArticlesLocal() {
    if (localStorage.getItem('articles') !== null) {
      this.articlesSelected = JSON.parse(localStorage.getItem('articles'));
      this.coutCart();
      this.loadCustom();
      //debugger;
    }
  }
  /**/
  /*api 1*/

  getCategories() {
    // this._api.genericGet('https://apiv2.kamgus.com/api/v2/categories').subscribe( (resp:any) => {
    //   this.categories = resp.data ;
    //   console.log(resp.data)
    // });
    this._api
      .genericGet(
        //'https://www.api.kamgus.com/usuario-v2/index.php/kamgus/getArticulosInvitado'
        environment.endpoints.articles.base
      )
      .subscribe((resp: any) => {
        this.categories = resp.articulos;
        // console.log(resp.articulos)
      });
  }

  /*Api de tarjetas*/
  cambiarCategoria(idCategoria) {
    this.selectArticles2(idCategoria);
  }

  cambiarCategoriaMovil(idCategoria) {
    this.selectArticles2(idCategoria);
  }

  /*selectArticles2() {

    this._api.genericGet( `https://apiv2.kamgus.com/api/v2/subcategories`).subscribe( (respo:any) => {
    this.subCategories = respo.data ;

    });

  }*/

  selectArticles2(idCategoria = 1) {
    // this._api.genericGet( `https://apiv2.kamgus.com/api/v2/categories/${idCategoria}/subcategories `).subscribe( (respo:any) => {
    // this.subCategories = respo.data ;
    // this.idCategoria=idCategoria
    // console.log(this.subCategories)

    const dataToSend = new FormData();
    dataToSend.append('id_nivel1', `${idCategoria}`);
    //   // call http request
    this._api
      .genericGet(
        //`https://www.api.kamgus.com/usuario-v2/index.php/kamgus/getArticulosNivel2Invitado`,
        `${environment.endpoints.articles.base}/${idCategoria}`
        //dataToSend
      )
      //this._api.genericGet(`https://apiv2.kamgus.com/api/v2/categories/${idCategoria}/subcategories `)
      .subscribe((respo: any) => {
        // console.log(respo.articulos)
        this.subCategories = respo.articulos;
       
        this.idCategoria = idCategoria;
        // console.log(this.subCategories)
      });
  }

  selectArticles() {
    // this._api.genericGet(`https://apiv2.kamgus.com/api/v2/articles`).subscribe( (respo:any) => {
    this._api
      .genericGet(
        //`https://www.api.kamgus.com/usuario-v2/index.php/kamgus/getArticulosNivel3AllInvitado`
        `${environment.endpoints.articles.show}/all`
      )
      .subscribe((respo: any) => {
        console.log(respo.articulos);
        this.articles = respo.articulos;
        if (!this.articles) {
          console.log('');
        } else {
          this.loader = false;
        }

        //console.log(this.articles)
      });
  }

  /**/
  /*
  cartArticle( article: string ) {
    const tmp = this.articles.find( item => item.id === article);
    //this.validateArticle( tmp );
    //this.articlesSelected= this.articles

  }
  validateArticle( article: object ) {
    const tmp = this.articlesSelected.filter( item => item.id === article['id'] );
   if ( tmp.length === 0 ) {
      article['cantidad'] = 1;
      this.articlesSelected.push( article );
      //console.log( this.articlesSelected );
      localStorage.setItem('articles', JSON.stringify( this.articlesSelected ) );
      this.loadArticlesLocal();
    }
  }
  */
  /**/
  addQuatity(index) {
    this.articlesSelected[index].cantidad =
      this.articlesSelected[index].cantidad + 1;
    localStorage.setItem('articles', JSON.stringify(this.articlesSelected));
    this.loadArticlesLocal();
  }

  removeQuatity(index) {
    if (this.articlesSelected[index].cantidad > 1) {
      this.articlesSelected[index].cantidad =
        this.articlesSelected[index].cantidad - 1;
      localStorage.setItem('articles', JSON.stringify(this.articlesSelected));
      this.loadArticlesLocal();
    }
  }
  changeQuatity(quantity: string, index: number){
    this.articlesSelected[index].cantidad = parseInt(quantity);
    localStorage.setItem('articles', JSON.stringify(this.articlesSelected));
    this.loadArticlesLocal();
  }
  delete(article) {
    const tmp = this.articlesSelected.filter(
      (item) => item.id !== article['id']
    );
    localStorage.setItem('articles', JSON.stringify(tmp));
    this.loadArticlesLocal();
  }

  loadForm() {
    this.formData = this.formBuilder.group({
      name: ['', [Validators.required]],
      categoria: ['', [Validators.required]],
      descripcion: ['', [Validators.required]],
      alto: ['', [Validators.required]],
      largo: ['', [Validators.required]],
      ancho: ['', [Validators.required]],
      peso: ['', [Validators.required]],
    });
  }
  get f() {
    return this.formData.controls;
  }

  addItem() {
    if (this.formData.valid) {
      var largoX: number = parseInt(this.formData.value.largo);
      var alturaX: number = parseInt(this.formData.value.alto);
      var anchoX: number = parseInt(this.formData.value.ancho);
      var m3X: number = (anchoX * largoX * alturaX / 1000000);

      var pesoX = (parseInt(this.formData.value.peso)*0.453592);
      const obj = {
        altura: alturaX,
        ancho: anchoX,
        cantidad: 1,
        id: `${Math.floor(Math.random() * (1000 - 100)) + 45}`,
        id_articulo_acarreo_1: this.formData.value.categoria,
        id_articulo_acarreo_2: 'custom',
        img: '',
        largo: largoX,
        //m3: this.formData.value.peso,
        peso: pesoX,  // Peso
        m3: m3X,  //Volumen (Dimensiones)
        name: this.formData.value.name,
        price: '10',
        descripcion: this.formData.value.descripcion,
        tc: (m3X * pesoX)/10, //Tiempo de Carga y Descarga
      };
      this.articlesSelected.push(obj);
      //console.log( this.articlesSelected );
      localStorage.setItem('articles', JSON.stringify(this.articlesSelected));
      this.loadArticlesLocal();
    }
  }
  /*2b */
  loadCustom() {
    this.customArticles = this.articlesSelected.filter(
      (item) => (item.sub_category_id === 'custom' || item.id_articulo_acarreo_2 === 'custom')
    );
  }
  /**/
  addQuatityCustom(custom: any) {
    const tmp = this.articlesSelected.findIndex(
      (item) => item.id === custom.id
    );
    if(tmp == -1){
      alert("Item no encontrado");
      return;
    }
    this.addQuatity(tmp);
  }
  removeQuatityCustom(custom: any) {
    const tmp = this.articlesSelected.findIndex(
      (item) => item.id === custom.id
    );
    if(tmp == -1){
      alert("Item no encontrado");
      return;
    }
    this.removeQuatity(tmp);
  }
  deleteCustom(custom: any) {
    const tmp = this.articlesSelected.find((item) => item.id === custom.id);
    this.delete(tmp);
  }
  next() {
    if (this.quantityCart > 0) {
      this.route.navigate(['/confirm-articles']);
    }
  }

  //BUSCADOR

  buscar() {
   // console.log(this.termino);
   // console.log(this.articles);
    let index = this.articles.findIndex((obj) => obj.nombre === 'test');
   // console.log(index);
    // https://www.api.kamgus.com/usuario-v2/index.php/kamgus/getArticulosNivel3All

    if (this.termino !== '') {
      const dataToSend = new FormData();
      dataToSend.append('name', this.termino);
      this._api
        .genericGet(
          //`https://www.api.kamgus.com/usuario-v2/index.php/kamgus/getArticulosNivel3SearchInvitado`,
          `${environment.endpoints.articles.search}/${this.termino}`
          //dataToSend
        )
        .subscribe((resp: any) => {
          this.subCategories = resp.articulos;
          // this.subCategories = respo.articulos;

          // console.log("termino--->",this.termino)
          // console.log("subCategories--->",this.subCategories)
          // console.log(resp.articulos)
        });
    } else {
    //  console.log('vacio');
    //  console.log(this.idCategoria);
      this.selectArticles2(this.idCategoria);
    }
  }

  inputValidator(event: any) {
    //console.log(event.target.value);
    const pattern = /^[A-Za-z0-9]*$/;
    //let inputChar = String.fromCharCode(event.charCode)
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z]/g, '');
      // invalid character, prevent input
    }
  }

  cotizar(){
    this.service = JSON.parse(localStorage.getItem('service'));
    this.service.options.typeVehicle ="vehiculo";
    //console.log("COTIZAR --- ",JSON.parse(localStorage.getItem('service')));
    localStorage.setItem('service', JSON.stringify(this.service));
    localStorage.removeItem('precio');
    localStorage.removeItem('vehiculos');
    localStorage.removeItem('articles');
    this.route.navigate(['/list-vehicle']);
  }
  
  //Devuelve los articulos de una categoria especificada
  filterArticles(id: number){
    return this.articles.filter(article => (id === article.id_articulo_acarreo_2));

  }
  hasCustomInThisCategory(){
    return (this.customArticles.find(p => p.id_articulo_acarreo_1 == this.idCategoria) !== undefined)
  }
  /*handleSearch(value:string) {
    this.filtro_valor = value
  }
  filtro_valor='';*/

  //SCROLL INFINITO
  /*
    add40lines() {
      const line = 0;
      let lineCounter = this.subCategories.length;
      for (let i = 0; i < 0; i ++) {
        this.subCategories.push(line + lineCounter);
        lineCounter ++;
      }

     }

     onScroll() {
      if (this.actualPage < this.finishPage) {
        this.add40lines();
        this.actualPage ++;
      } else {
        console.log('No more lines. Finish page!');
      }
    }

*/

  /*
    onscroll(){
      if(this.notscrolly && this.notEmptyPost){
        this.spinner.show();
        this.notscrolly = false;
        this.loadNextPost();

      }
    }

    loadNextPost() {
      console.log('ss')
     /* const url = 'https://api.kamgus.tk/api/v2/categories/1/subcategories';
      // return last post from the array
      const lastPost = this.subCategories[this.subCategories.length - 1];
      // get id of last post
      const lastPostId = lastPost.id;
      // sent this id as key value pare using formdata()
      const dataToSend = new FormData();
      dataToSend.append('id', lastPostId);
      // call http request
      this._api.genericPost(url, dataToSend)
      .subscribe( (data: any) => {
         const newPost = data[0];
         this.spinner.hide();
         if (newPost.length === 0 ) {
           this.notEmptyPost =  false;
         }
         // add newly fetched posts to the existing post
         this.subCategories = this.subCategories.concat(newPost);
         this.notscrolly = true;
       });

    }
      */
}
/*
    primer intento de buscador

buscar(){
    console.log(this.termino);
    this._api.genericGet( `https://apiv2.kamgus.com/api/v2/articles/search/${this.termino}`).subscribe( (respo:any) => {
    this.articulos2 = respo ;
    //console.log(this.subCategories)

    //console.log(this.articulos)

    this.idSubcategoria = this.articulos2.map( item => item.sub_category_id );
    //console.log(this.idSubcategoria)

    var valor = 0

      for (var contador = 0; contador < this.idSubcategoria.length; contador++ ){
       valor = this.idSubcategoria[contador];
       var ca= this.subCategories.filter( item => item.id == valor )
       this.subCategoriesSearch.push(ca);
       //console.log(ca);
        };

        console.log(this.subCategoriesSearch)



    for (var contador = 0; contador < this.idSubcategoria.length; contador++ ){
       valor = this.idSubcategoria[contador];
       var ca= this.subCategories.filter( item => item.id == valor )
       console.log(ca)
       ca.push(this.subCategoriesSearch);
        };
        console.log(this.subCategoriesSearch)




    this.subCategories = this.subCategories.filter( item => item.id === this.idSubcategoria[contador]);
  });

}

*/
