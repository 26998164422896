<div class="modal-body mt-5">
    <!--
    <form class="row" *ngIf="formAddCard" [formGroup]="formAddCard">
        <div class="col-md-12">
          <div class="form-group">
            <input autocomplete="none" type="text" class="form-control" placeholder="Nombre de la tarjeta" formControlName="nameCard">
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <input autocomplete="none" type="text" class="form-control" placeholder="Número de la tarjeta" formControlName="numberCard" mask="0000 0000 0000 0000">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <input autocomplete="none" type="text" class="form-control" placeholder="Año" formControlName="yearExpired" mask="00">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <input autocomplete="none" type="text" class="form-control" placeholder="Mes" formControlName="monthExpired" mask="00">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <input autocomplete="none" type="text" class="form-control" placeholder="CVV" formControlName="cvv" maxlength="4">
          </div>
        </div>
      
    </form>
    -->
    <ion-spinner name="crescent" *ngIf="loading" ></ion-spinner>

    <form id="payment-form">
      <div id="payment-element">
        <!-- Elements will create form elements here -->
      </div>
      <div id="error-message">
        <!-- Display error message to your customers here -->
      </div>
    </form>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
      <button type="button" class="btn btn-primary btn-warning" (click)="addCards()" data-dismiss="modal" [disabled]="disabled">Guardar tarjeta</button>
  </div>
