import { Component, OnInit } from '@angular/core';
import { SharedService } from './SharedService/shared.service';
import { Router } from '@angular/router';

//api
import { ApiService } from '../../servicesApi/api/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-vehicle',
  templateUrl: './list-vehicle.component.html',
  styleUrls: ['./list-vehicle.component.css'],
  providers: [ApiService, SharedService],
})
export class ListVehicleComponent implements OnInit {
  data;
  vehicles;
  service;
  estado;
  selVehicle;
  errorMesssage = '';
  loader: boolean = true;

  constructor(
    private _api: ApiService,
    private _sh: SharedService,
    public router: Router
  ) {}

  ngOnInit() {
    this.getVehicles();
    this.service = JSON.parse(localStorage.getItem('service'));
  }

  getVehicles() {
    this._api
      .genericGet(
        `${environment.endpoints.vehicle.base}`
      )
      .subscribe(
        (resp: any) => {
          // console.log(resp.trucks);
          this.vehicles = resp.trucks;
          if (!this.vehicles) {
            console.log('');
          } else {
            this.loader = false;
          }
          //this.estado= this.service.vehicles.estado
          localStorage.setItem('vehiculos', JSON.stringify(resp));
          const vehiculos = JSON.parse(localStorage.getItem('vehiculos'));

          //console.log(this.estado)
          //console.log(this.vehicles.estado)
          this.vehicles.forEach((element) => {
            element.precioTotal = this._sh.calculatePriceByVehicule(
              this.service.time.duration.value,
              element.precio_minuto,
              element.precio_ayudante,
              element.tiempo
            );
            // console.log('======', element.precioTotal)
          });
          //console.log( this.vehicles );
        },
        (err) => {
          console.log(err);
        }
      );
  }
  selected(vehicle, i) {
    this.selVehicle = vehicle;
    this.selVehicle.position = i;
    // console.log('selVehicle ----', this.selVehicle);
    localStorage.setItem('selVehicle', JSON.stringify(this.selVehicle));
  }
  next() {
    this.service.vehicle = this.selVehicle;
    localStorage.setItem('service', JSON.stringify(this.service));
    localStorage.setItem('typeVehicle', 'true');
    this.router.navigate(['/summary']);
  }

  cotizar(){
    //this.service = JSON.parse(localStorage.getItem('service'));
    this.service.options.typeVehicle ="articulo";
    //console.log("COTIZAR --- ",JSON.parse(localStorage.getItem('service')));
    localStorage.setItem('service', JSON.stringify(this.service));    
    this.router.navigate(['/list-article']);
  }
  //En caso de no cargar la imagen remota se cargara una imagen local del mismo nombre
  reloadImage(url: string, event: any){
    if (url.indexOf("assets") < 0) {      
      const linkArray = url.split("/");
      event.target.src = "../../../assets/images/vehicles/"+linkArray[linkArray.length - 1];
    }
    console.log(url, event)
  }
}
