const BASE_HOST = 'https://apikamgusv2.kamgus.com/api/v2/invited/';
export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyACMCm4Nm18ik5JChrcpcEmSJxRlMSpP4Y",
    authDomain: "kamgus.firebaseapp.com",
    databaseURL: "https://kamgus.firebaseio.com",
    projectId: "kamgus",
    storageBucket: "kamgus.appspot.com",
    messagingSenderId: "827183164021",
    appId: "1:827183164021:web:402ca75ce3a881c483e63c",
    measurementId: "G-YDZPG0P20R",
    vapidKey: "BN1viSre-4pBVg8MwcfIXFnpUQRZDFDQvMHh8oLTCoX3cjskW-jtTZwOrU-ePS9jbu2eT_5NgtGoPI7sZ5oUzAo"
  },
  pkStripe: 'pk_live_51K6crsEumLY5hcKQ662RmgRGeUJZwNTskwGAFNdo7dUisOFMI8NtlQayWetMlYgryfrfmtaIClUfrf6xQTqLI7HV00b4ugc4fY',
  END_STRIPE:'https://www.api.kamgus.com/usuario-v2/index.php/StripeCustomer/',
  version: "2.2.01",
	//pkStripe: 'pk_test_51K6crsEumLY5hcKQ8p1xZ1eeBirue6dKTk8e2mUj7G78nWgeVPcCFORzRyEdpTWyr1xjHjz8PWNxC4cTb3uxFh1I00ajY5UcvI',
  endpoints:{
    articles: {
      base: `${BASE_HOST}articles`,
      show: `${BASE_HOST}article`,
      search: `${BASE_HOST}article/search`,
    },
    vehicle: {
      base: `${BASE_HOST}vehicles`,
    },
    transaction_status: `${BASE_HOST}transaction_status`,
    payment: `${BASE_HOST}service_payment`,
    services: {
      base: `${BASE_HOST}services`,
      cancel: `${BASE_HOST}services/cancel`,
      suggested_price: `${BASE_HOST}services/suggested_price`,
      confirm_driver: `${BASE_HOST}services/confirm_driver`,
      sse: {
        suggested_price: `${BASE_HOST}sse/service`,
      }
      
    },
    customer: {
      base: `${BASE_HOST}user`,
      fcm_token: `${BASE_HOST}user`,
    },
  }
};
