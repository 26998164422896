import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
//import {delay} from 'rxjs/operators';
import { articulosSmall } from '../../Pages/list-article/interfaces/articulos.interface';
import { catchError } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';

@Injectable()
export class ApiService {
  constructor(public http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }),
  };

  genericPut(url, params) {
    //return this.http.post(url, params, this.httpOptions);
    return this.http.put(url, params, { responseType: 'json' });
  }
  genericPost(url, params) {
    //return this.http.post(url, params, this.httpOptions);
    return this.http.post(url, params, { responseType: 'json' });
  }

  genericPostServi(url, params) {
    //return this.http.post(url, params, this.httpOptions);
    return this.http.post(url, params, { responseType: 'json' });
  }

  genericGetNow(url) {
    return this.http.get(url);
  }

  genericGet(url) {
    return this.http.get(url).pipe(catchError(this.errorHandled));
  }
  genericGetParams(url, params) {
    return this.http.get(url, params).pipe(catchError(this.errorHandled));
  }

  errorHandled(error: HttpErrorResponse) {
    return observableThrowError(error.message);
  }

  articulosGet(url) {
    return this.http.get<articulosSmall[]>(url);
  }
}
