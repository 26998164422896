<app-header></app-header>
<div class="ContenedorListVehicle">
  <div class="row">
    <div class="col-md-12 titulo">
      <div class="contenedorBotones2">
        <div class="col-md-3 btnregresar2">
          <button
            type="button"
            class="btn waves-effect waves-light btn-rounded btn-secondary btn1responsive"
            routerLink="/"
          >
            <i class="fa fa-arrow-left"></i> REGRESAR
          </button>
        </div>
        <div class="col-md-4 btnregresar2">
          <button
            type="button"
            class="btn btn-rounded btn1"
            (click)="cotizar()"
          >
            <span>COTIZAR POR ARTÍCULO</span>
          </button>
        </div>

        <!--
        <div class="col-md-5 btnenviar2">
          <button
            type="button"
            class="col-xs-12 col-md-8 btn waves-effect waves-light btn-rounded btn-secondary"
            (click)="next()"
            *ngIf="selVehicle !== undefined"
            routerLink="/"
          >
            <span>ENVIAR PEDIDOS</span>
          </button>
        </div>
        -->
      </div>
      <p class="mt-3">
        <span>PEDIDO VEHÍCULO</span>
      </p>
    </div>
    <!--
  <div class="contenedorBotones col-md-4">

    <div class="contenedorBotoness col-md-4"  *ngIf="selVehicle == undefined">

    </div>

    <div class="btnregresar">
      <button type="button"
      class="btn waves-effect waves-light btn-rounded btn-secondary " routerLink="/">REGRESAR</button>
    </div>

    <div class="btnenviar">
      <button type="button"
      class="btn waves-effect waves-light btn-rounded btn-secondary "
      (click)="next()"
       *ngIf="selVehicle !== undefined"
      routerLink="/"><span>ENVIAR PEDIDOS</span></button>
    </div>
</div>
-->
    <!--
  <div class="col-md-4 btnregresar">
    <button type="button"
    class="btn waves-effect waves-light btn-rounded btn-secondary " routerLink="/">REGRESAR</button>
  </div>
  btn2
  btn
   <div class="col-md-6 btnregresar">
    <button
      type="button"
      class="waves-effect waves-light btn-rounded  "
      *ngIf="selVehicle !== undefined"
      (click)="next()"
    >
      <span>ENVIAR PEDIDOS</span>
    </button>
  </div>
  --></div>
  <hr />
  <p class="subtitulo" style="color: black">
    Seleccione su vehículo de acuerdo a la cantidad o tamaño que desee llevar
    <br />
    <strong style="color: #eb6133"
      >Todos nuestros servicios incluyen ayudante</strong
    >
  </p>

  <div class="card card-kg">
    <div class="card-body">
      <div class="row mg">
        <div class="col-lg-2 col-md-4 subtitle">
          <h4>VEHÍCULO</h4>
        </div>
        <div class="col-lg-9 col-md-8 subtitle">
          <h4>TIPO DE VEHÍCULO</h4>
        </div>
        <!--
      <div class="col-md-2 text-center subtitle">
        <h4>PRECIO</h4>
      </div>
    -->
      </div>
      <div class="row mg"></div>

      <app-loader *ngIf="loader === true"></app-loader>
      <!--<p class="ErrorMensaje">{{ this.errorMesssage }}</p>-->
      <div
        class="row mg click"
        *ngFor="let vehicle of vehicles; let i = index"
        (click)="selected(vehicle, i)"
        [ngClass]="{
          'vehicle-selected':
            selVehicle !== undefined && i === selVehicle.position
        }"
      >
        <div class="col-lg-2 col-md-4">
          <span
            [ngClass]="{
              green: selVehicle !== undefined && i === selVehicle.position
            }"
            class="far fa-circle opacity-0"
          ></span>
          <img
            [src]="vehicle.foto"
            class="img-width-100 img-90 efectoHover"
            (error)="reloadImage(vehicle.foto, $event)"
          />
        </div>
        <div class="col-lg-9 col-md-8 contenText">
          <h6>
            <strong class="detallesvehivulo">{{
              vehicle.nombre_camion
            }}</strong>
          </h6>
          <p>{{ vehicle.descripcion }}</p>
          <p>
            <strong class="detallesvehivulo"
              >Capacidad: {{ vehicle.capacidad }} m³</strong
            >
            <br />
            <strong class="detallesvehivulo"
              >Peso:{{ vehicle.capacidad_peso }} Tonl</strong
            >
          </p>
          <div>
            <button
              type="button"
              class="btn waves-effect waves-light btn-rounded btn-send-vehicle-selected"
              *ngIf="
                selVehicle !== undefined &&
                selVehicle !== undefined &&
                i === selVehicle.position
              "
              (click)="next()"
            >
              <span>ENVIAR PEDIDOS</span>
            </button>
          </div>
        </div>
        <!--
      <div class="col-md-2 text-center auto-align">
        <h4 class="pricing-orange subtitle">{{ vehicle.precioTotal | number }} USD</h4>
      </div>
        *ngif="vehicle.estado"
    -->
      </div>
    </div>
    <!--

      <div class="col-md-12 text-right mt-5 mb-5">
        <button
        type="button"
        class="btn waves-effect waves-light btn-rounded btn2"
        *ngIf="selVehicle !== undefined"
        (click)="next()"
        >
        <span>ENVIAR PEDIDOS</span>
      </button>
    </div>
  --></div>
  <br />
  <br />
  <app-footer *ngIf="loader === false"></app-footer>
</div>
