import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ApiService } from '../../servicesApi/api/api.service';
import { SharedService } from '../list-vehicle/SharedService/shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { EmmitersService } from '../../servicesApi/emmitersService/emmiters.Service';
import { StripeService } from 'src/app/services/StripeService/stripe-service.service';
import { StripeElements, StripePaymentElement } from '@stripe/stripe-js';

declare var Stripe: any;

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.css'],
  providers: [ EmmitersService ]
})
export class AddCardComponent implements OnInit {

  formAddCard: FormGroup;
  user;
  disabled: boolean = false;
  private lStripe:any;
  private elements: StripeElements;
  public paymentElement:StripePaymentElement;

  public loading: Boolean = true;
  @Input() client_secret: any = {};
  @Output() onCardSaved = new EventEmitter()

  constructor(private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private apiService: ApiService,
    private _emmit: EmmitersService,
    private stripeService:StripeService) { }

  ngOnInit() {
    /*this.user = this.sharedService.decodeJwt();*/
    this.loadForm();
    this.lStripe = Stripe(this.stripeService.pk)
    if(this.client_secret && this.client_secret.length > 0){

      const options = {
        clientSecret: this.client_secret,
        // Fully customizable with appearance API.
        appearance: {/*...*/},
        loader:  'always',
      };
      
      // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
      this.elements = this.lStripe.elements(options);
      
      // Create and mount the Payment Element
      this.paymentElement = this.elements.create('payment');
      this.paymentElement.mount('#payment-element');
      this.loading = false;
    }
  }
  ngDoCheck(): void {
    //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    //Add 'implements DoCheck' to the class.
    
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    
  }
  loadForm() {
    this.formAddCard = this.formBuilder.group({
      nameCard: [ '', [ Validators.required ] ],
      numberCard: [ '', [ Validators.required ] ],
      yearExpired: [ '', [ Validators.required ] ],
      monthExpired: [ '', [ Validators.required ] ],
      cvv: [ '', [ Validators.required ] ],
    });
  }
  get f() { return this.formAddCard.controls; }

  addCards() {
    this.continueToCheckOut();
    return;
    if ( this.formAddCard.valid ) {
      const request = this.updateModel( this.formAddCard.value );
      //console.log( request );
      localStorage.setItem('addCard', JSON.stringify( this.formAddCard.value ));
      /*this.apiService.genericPost('/addCard', request ).subscribe( response => {
        if ( !response['error']) {
          this.sharedService.showSuccess('Tarjeta agregada exitosamente');
          this._emmit.reloaadGetCards();
        } else {
          this.sharedService.showError('Algo sucedio, intentlo nuevamente');
        }
      }, err => {
        this.sharedService.showError('Algo sucedio, intentlo nuevamente');
        console.log( 'error add card', err );
      });*/
    }
  }
  
  updateModel( value: any ) {

    if ( value.monthExpired > 1 && value.monthExpired < 10 ) {
      value.monthExpired = '0' + value.monthExpired;
    }
    const params = new HttpParams()
        /*.set('customerId', this.user.CustomerId )*/
        .set('name', value.nameCard )
        .set('month', value.monthExpired )
        .set('year', value.yearExpired )
        .set('cardNumber', value.numberCard )
        .set('cvv', value.cvv )
        /*.set('UniqueIdentifier', this.user.UniqueIdentifier )*/
        /*.set('idusuario', this.user.idusuarios );*/
    return params;
  }
  
  async continueToCheckOut(){
    //this.modalController.dismiss(this.cardForm.value);
    /*this.stripeService.confirmSetupIntent(this.client_secret, this.elements).subscribe((setupIntent) => {
      console.log("Setup Intent", setupIntent);
    }, (err) => {
      const messageContainer = document.querySelector('#error-message');
      messageContainer.textContent = err.message;
    });
    */
    
    const {error, setupIntent} = await this.lStripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements:this.elements,
      confirmParams: {
        //return_url: 'https://www.api.kamgus.com/usuario-v2/index.php/StripeCustomer/paymentSuccessfully?service='+this.service_id,
        return_url: 'https://apikamgusv2.kamgus.com/api/v2/stripe/redirected?status=successfully',
      },
      redirect: 'if_required'
    });
    console.log(setupIntent);
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      const messageContainer = document.querySelector('#error-message');
      messageContainer.textContent = error.message;
      this.disabled = true;
      alert("Error agregando tarjeta");
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      //this.modalController.dismiss({client_secret: setupIntent.client_secret, payment_method: setupIntent.payment_method});
      this.onCardSaved.emit({client_secret: setupIntent.client_secret, payment_method: setupIntent.payment_method});
      this.disabled = false;      
      return
    }
    
  }

}
