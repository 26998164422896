import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/servicesApi/api/api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  public pk: string = environment.pkStripe; //Production stripe key
  private endpoint: string = environment.endpoints.payment;
  constructor(private _api: ApiService) { 


  }
  //--------------------------	Crhistian L 	---------------------
  initializeSetupIntent(): Observable<any>{
    //solicitar setupintent 
    /**
     *  {
            "client_secret": "seti_1MhcKQW376QzmA_secret_...",
            "customer_id": "cus_NSX...."
        }
     */
    return this._api.genericPost(this.endpoint+"createCustomer",{});
  }
  //Inicia un proceso de pago con stripe de tipo Checkout
  initializeCheckoutSession(params: any): Observable<any>{
    return this._api.genericPost(`${environment.endpoints.payment}?type=1`, params);
  }
  //Consulta el estado de un pago hecho con stripe
  refreshCheckoutSessionStatus(params: any): Observable<any>{
    return this._api.genericPut(`${environment.endpoints.payment}/${params.idservicio}`, {});

  }
}
