import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule, IConfig } from 'ngx-mask';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
//Ng autocomplete
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

//Scroll Infinito
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from 'ngx-spinner';

//Mapas
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
//Pages
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PointToPointComponent } from './Pages/point-to-point/point-to-point.component';
import { ListVehicleComponent } from './Pages/list-vehicle/list-vehicle.component';
import { ListArticleComponent } from './Pages/list-article/list-article.component';
import { ConfirmArticlesComponent } from './Pages/confirm-articles/confirm-articles.component';
import { SummaryComponent } from './Pages/summary/summary.component';
import { DriversConfirmationComponent } from './Pages/drivers-confirmation/drivers-confirmation.component';
//Componentes
import { HeaderComponent } from './shared/header/header.component';
import { AddCardComponent } from './Pages/add-card/add-card.component';
import { ImfoUsuarioComponent } from './Pages/summary/imfo-usuario/imfo-usuario.component';
import { FooterComponent } from './shared/footer/footer.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { ThankyouComponent } from './Pages/thankyou/thankyou.component';
import { BuscadorComponent } from './Pages/list-article/buscador/buscador.component';
import { SearchPipe } from './pipes/search.pipe';
import { AlertComponent } from './shared/alert/alert.component';
import { CarruselComponent } from './Pages/list-article/carrusel/carrusel.component';
import { ModalesIconosComponent } from './Pages/summary/modales-iconos/modales-iconos.component';
import { ModalesIconos2Component } from './Pages/summary/modales-iconos2/modales-iconos2.component';
import { ModalesIconos3Component } from './Pages/summary/modales-iconos3/modales-iconos3.component';

import { BarComponent } from './pipes/appprogress/progress-bar.component';
import { DriversComponent } from './Pages/drivers-confirmation/drivers-component/drivers.component';

import { CommonModule, DatePipe } from '@angular/common';

/** Firebase */
import { environment } from "../environments/environment.prod";
import { initializeApp } from "firebase/app";
import { StripeService } from './services/StripeService/stripe-service.service';
import { ApiService } from './servicesApi/api/api.service';
import { SharedService } from './Pages/list-vehicle/SharedService/shared.service';
import { NavigationService } from './services/Navigation/navigation.service';
initializeApp(environment.firebase);
/** *** */
@NgModule({
  declarations: [
    AppComponent,
    PointToPointComponent,
    HeaderComponent,
    ListVehicleComponent,
    ListArticleComponent,
    ConfirmArticlesComponent,
    SummaryComponent,
    DriversConfirmationComponent,
    AddCardComponent,
    ImfoUsuarioComponent,
    FooterComponent,
    LoaderComponent,
    ThankyouComponent,
    BuscadorComponent,
    SearchPipe,
    AlertComponent,
    CarruselComponent,
    ModalesIconosComponent,
    ModalesIconos2Component,
    ModalesIconos3Component,
    BarComponent,
    DriversComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC8vsLlcGNy-7rC5Uajw6KjyCWmjs-pd-o',
      libraries: ['places'],
    }),
    AgmDirectionModule,
    GooglePlaceModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    NgxSpinnerModule,
    TextMaskModule,
    NgxMaskModule.forRoot(),

    HttpClientModule,
    InfiniteScrollModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    CommonModule,
    
  ],
  providers: [StripeService, ApiService, SharedService, NavigationService, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
